@import 'media-query.mixins';

:root {
  --_defaultModalMapWidth: 100%;
  --_defaultModalMapHeight: 50vh;
}

.map {
  width: var(--modalMapWidth, --_defaultModalMapWidth);
  height: var(--modalMapHeight, --_defaultModalMapHeight);
}

.p-dialog.map-dialog {
  --buttonBackground: transparent;
  --buttonColor: #495057;
  --buttonBackgroundHover: transparent;
  --buttonColorHover: #495057;
  --buttonBorder: transparent;
  --buttonBorderHover: transparent;

  width: 80vw;
  @media (width > 992px) {
    width: 50vw;
  }
}
