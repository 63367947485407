
    .load-more-cinemas__button {
      position: relative;
      font-weight: bold;
      border: none;
      width: max-content;
      cursor: pointer;
      background: transparent;
      margin: 20px auto;
      font-size: 20px;
      font-family: inherit;
      span {
        color: var(--componentInCinemas-nearestCity-headerTextColor, var(--textColor));
      }
      &:hover,
      &:focus,
      &:active {
        span {
          text-decoration: underline;
          text-decoration-color: var(--brandColor);
          text-decoration-thickness: 2px;
          text-underline-offset: 0.3em;
        }
        span.load-more-icon {
          text-decoration: none !important;
        }
      }
      .load-more-icon {
        position: absolute;
        top: 0;
        left: -15px;
        margin-right: 10px;
        color: var(--brandColor);
      }
    }
  