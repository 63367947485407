.usheru-logo {
  margin-top: 10px;
  opacity: 1;
  text-align: end;
  a {
    text-decoration: none;
    color: var(--ush-logo-color, #000000);
  }
  .usheru-name {
    text-decoration: underline;
    margin-left: 6px;
  }
}
